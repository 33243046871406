import type { Event, EventProcessor, Hub, Integration } from '@sentry/types';

// fixes sentry infinite loop when capturing console errors with adblock enabled
export class IgnoreIntegrationErrors implements Integration {
    static id = 'IgnoreIntegrationErrors';
    name = IgnoreIntegrationErrors.id;

    _shouldDropEvent(currentEvent: Event): boolean {
        // return true;
        const exception = currentEvent?.exception?.values?.[0];
        if (!exception) return false;
        const frames = exception?.stacktrace?.frames || [];
        const isFetch = exception.value === 'Failed to fetch';
        const isFromConsole = frames
            // .slice(0, 5)
            .some((frame) => frame.function?.startsWith?.('console.')); // console.overrideMethod [as error]
        const isRrweb = frames
            // .slice(-5, -1)
            .some((frame) =>
                frame.function?.startsWith?.('SentryRRWeb.processEvent')
            );
        return isFetch && (isFromConsole || isRrweb);
        return false;
    }

    public setupOnce(
        addGlobalEventProcessor: (callback: EventProcessor) => void,
        getCurrentHub: () => Hub
    ): void {
        const eventProcessor: EventProcessor = (currentEvent) => {
            // We want to ignore any non-error type events, e.g. transactions or replays
            // These should never be deduped, and also not be compared against as _previousEvent.
            if (currentEvent.type) {
                return currentEvent;
            }

            const self = getCurrentHub().getIntegration(
                IgnoreIntegrationErrors
            );
            if (!self) return currentEvent;
            // Juuust in case something goes wrong
            try {
                if (self._shouldDropEvent(currentEvent)) {
                    return null;
                }
            } catch (_oO) {
                return currentEvent;
            }

            return currentEvent;
        };

        eventProcessor.id = this.name;
        addGlobalEventProcessor(eventProcessor);
    }
}
