import { format } from 'date-fns';
export const checkDateType = (date: any) => {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    return date;
};

export const currentDateUTC = Date.now();
export const currentDate = new Date();

export const getStripeDate = (date: number, dateFormat = 'MM-dd-yy') => {
    if (!date) {
        return '';
    }
    return format(checkDateType(date * 1000), dateFormat);
};
