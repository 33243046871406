import '../styles/globals.css';
import { RouteGuard } from '../components/routeGuard';
import { AuthProvider } from '../utils/auth';
import { configure } from 'mobx';
import Script from 'next/script';
import * as amplitude from '@amplitude/analytics-browser';
import { useEffect } from 'react';
import { HistoryProvider } from '@/contexts/History';

configure({
    enforceActions: 'never',
});

const Analytics = () => {
    const useAnalytics = process.env.NEXT_PUBLIC_CURRENT_ENV === 'PRODUCTION';

    useEffect(() => {
        if (!useAnalytics) return;
        amplitude.init('e47f1299532f8652b1ae3cd9d3b5f3c5', undefined, {
            defaultTracking: {
                sessions: true,
                pageViews: true,
                formInteractions: true,
                fileDownloads: true,
            },
        });
    }, [useAnalytics]);

    if (!useAnalytics) return <></>;
    return (
        <>
            <Script
                src="https://www.googletagmanager.com/gtag/js?id=G-X0T61BFTS4"
                defer
            />
            <Script
                defer
                dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-X0T61BFTS4');
                    `,
                }}
                id="gtag"
            />
            <Script
                defer
                dangerouslySetInnerHTML={{
                    __html: `
                        (function(h,o,t,j,a,r){
                            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                            h._hjSettings={hjid:3399723,hjsv:6};
                            a=o.getElementsByTagName('head')[0];
                            r=o.createElement('script');r.async=1;
                            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                            a.appendChild(r);
                        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                    `,
                }}
                id="hotjar"
            />
            <Script
                defer
                dangerouslySetInnerHTML={{
                    __html: `
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-MM8K2G7');
                    `,
                }}
                id="gtm"
            />
            <noscript>
                <iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-MM8K2G7"
                    height="0"
                    width="0"
                    style={{
                        display: 'none',
                        visibility: 'hidden',
                    }}
                />
            </noscript>
        </>
    );
};

function MyApp({ Component, pageProps }) {
    return (
        <>
            <Analytics />
            <AuthProvider>
                <RouteGuard>
                    <HistoryProvider>
                        <Component {...pageProps} />
                    </HistoryProvider>
                </RouteGuard>
            </AuthProvider>
        </>
    );
}

export default MyApp;
