import React, { useState, useContext, createContext } from 'react';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { authFetch } from '@/utils/authFetch';
import { stripeCheckoutSubmit } from '@/components/stripe/StripePay';

type UnixTimestamp = number;
export type GoogleProfile = {
    aud: string;
    azp: string;
    email: string;
    email_verified: boolean;
    exp: UnixTimestamp;
    family_name: string;
    given_name: string;
    iat: UnixTimestamp;
    iss: string;
    jti: string;
    name: string;
    nbf: UnixTimestamp;
    picture: string;
    sub: string;
};
export type GoogleCredentials = {
    credential: string;
    profileObj: GoogleProfile;
};

const authContext = createContext<ReturnType<typeof useProvideAuth>>(
    null as any
);

export const AuthProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const auth = useProvideAuth();

    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
    return useContext(authContext);
};

function useLocalStorage<T>(key: string, initialValue: T) {
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (typeof window === 'undefined') {
            return initialValue;
        }
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: React.SetStateAction<T>) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            // Save to local storage
            if (typeof window !== 'undefined') {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            console.error(error);
        }
    };
    return [storedValue, setValue] as const;
}

function useProvideAuth() {
    const [cookies, setCookie, removeCookie] = useCookies(['bonda-auth']);
    const [authToken, setAuthToken] = useState<string>(
        cookies['bonda-auth'] || ''
    );
    const [authErrorMessage, setAuthErrorMessage] = useState<string>('');
    const [companyId, setCompanyId] = useLocalStorage('companyId', '');
    const [profileRole, setProfileRole] = useLocalStorage('role', '');
    const [teamId, setTeamId] = useLocalStorage('teamId', '');
    const router = useRouter();

    const isSignedIn = () => {
        if (authToken) {
            return true;
        } else {
            return false;
        }
    };

    const signInWithGoogle = async (
        response: GoogleCredentials,
        fromWhere?: string,
        token?: string
    ) => {
        fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT_URL}/web/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: `${response?.profileObj?.email}`,
                username: `${response?.profileObj?.email}`,
                photo: `${response?.profileObj?.picture}`,
                name: `${response?.profileObj?.name}`,
                role: profileRole,
                companyId: companyId || '',
                loginType: 'GOOGLE',
                googleAccessToken: `${response?.credential}`,
            }),
        })
            .then((data) => data.json())
            .then(async (data) => {
                let serverCompanyId = companyId;
                if (data?.data?.accessToken) {
                    setAuthToken(data?.data?.accessToken);
                    setCookie('bonda-auth', data?.data?.accessToken, {
                        path: '/',
                    });

                    await authFetch(data?.data?.accessToken, `/web/user`).then(
                        (response) => {
                            serverCompanyId =
                                response?.data?.companyDetail?._id;
                            serverCompanyId && setCompanyId(serverCompanyId);
                        }
                    );
                }
                if (!data?.status) {
                    if (!serverCompanyId && !companyId) {
                        router.push({
                            pathname: '/registration/company-creation',
                        });
                        return;
                    }
                    if (data?.msg) {
                        console.error(data);
                        throw data.msg;
                    }
                }

                if (fromWhere === 'companyCreation') {
                    stripeCheckoutSubmit(
                        data?.data?.accessToken,
                        'registration/team-creation/',
                        10
                    );
                    return;
                }
                const returnUrl = router.query.returnUrl || '/';
                router.push(returnUrl + '');
            })
            .catch((e) => {
                setAuthErrorMessage(e.message || e);
            });
    };

    const signOut = (url = '') => {
        setAuthToken('');
        setTeamId('');
        setCompanyId('');
        removeCookie('bonda-auth', { path: '/' });
        router.push(url ? url : '/login');
    };

    return {
        teamId,
        setTeamId,
        companyId,
        setCompanyId,
        setProfileRole,
        authToken,
        authErrorMessage,
        isSignedIn,
        signInWithGoogle,
        signOut,
    };
}
