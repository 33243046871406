import { useAuth } from '@/utils/auth';
import { authFetch } from '@/utils/authFetch';
import { goToStripePortal } from '@/utils/subscriptionUtils';
import Router from 'next/router';
import React, { useState, useEffect } from 'react';

export const stripeCheckoutSubmit = async (
    authToken,
    redirectUrl = '',
    quantity,
    productId = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_ID
) => {
    await authFetch<any>(authToken, `/web/stripe/products/${productId}`)
        .then((response) => response.data)
        .then((price) => {
            return authFetch(authToken, `/web/stripe/checkout`, 'POST', {
                priceId: price.id,
                redirectUrl,
                quantity: quantity,
            });
        })
        .then((checkout) => Router.push(checkout?.data?.redirectUrl || '/'));
};

export const stripeCheckoutSubmitPriceId = async (
    authToken,
    redirectUrl = '',
    quantity,
    priceId,
    subscriptionDuration = 'month'
) => {
    await authFetch(authToken, `/web/stripe/checkout`, 'POST', {
        priceId,
        redirectUrl,
        quantity: quantity,
        subscriptionDuration,
    }).then((checkout) => Router.push(checkout?.data?.redirectUrl || '/'));
};

const Message = ({ message }) => (
    <section>
        <p>{message}</p>
    </section>
);

export default function StripePayComponent(props: {
    portalUrl?: string;
    userHasBillingInfo: boolean | string;
    userHasActiveSubscription: boolean;
}) {
    const [message, setMessage] = useState('');
    const { authToken } = useAuth();

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setMessage('Order placed! You will receive an email confirmation.');
        }

        if (query.get('canceled')) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);

    if (message) return <Message message={message} />;
    return (
        <section className="flex gap-2 justify-between items-end">
            {!props.userHasActiveSubscription && (
                <div>
                    <button
                        // onClick={() => stripeCheckoutSubmit(authToken, '')}
                        className="bg-purple text-white px-4 py-2 rounded-md whitespace-nowrap"
                    >
                        Purchase seats
                    </button>
                </div>
            )}
        </section>
    );
}

export const ManageSubscriptionPortal = (props: {
    portalUrl?: string;
    userHasBillingInfo: boolean | string;
    userHasActiveSubscription: boolean;
}) => {
    const [message, setMessage] = useState('');
    const { authToken } = useAuth();

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setMessage('Order placed! You will receive an email confirmation.');
        }

        if (query.get('canceled')) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);

    if (message) return <Message message={message} />;
    return (
        <section className="flex gap-2 justify-between items-end">
            {props.userHasBillingInfo && (
                <div className="flex w-full">
                    <button
                        onClick={() => goToStripePortal(authToken, '')}
                        className="bg-purple text-white px-4 py-2  whitespace-nowrap ml-auto"
                    >
                        Payment Settings
                    </button>
                </div>
            )}
        </section>
    );
};
