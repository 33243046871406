import { SubscriptionStatus } from '@/utils/apiTypes';
import { authFetch } from '@/utils/authFetch';
import { checkDateType, currentDate } from '@/utils/dateUtils';

export const subStatusCompare = (
    subscriptionStatus: SubscriptionStatus,
    statusesToCheck: SubscriptionStatus[]
) => {
    return statusesToCheck.some((status) => status === subscriptionStatus);
};

export const blockedBySubscription = (subStatus: SubscriptionStatus) => {
    return !subStatusCompare(subStatus, ['active', 'trialing']);
};

export const subscriptionExpired = (endDate) => {
    const dateExpired = currentDate > checkDateType(endDate);
    console.log('dateExpired: ' + dateExpired);
    return dateExpired;
};

export const goToStripePortal = (authToken, suffix) => {
    authFetch(authToken, `/web/stripe/portal`, 'POST', {
        returnUrl: window.location.href,
        suffix,
    }).then((res) => window.open(res.data.portalSessionUrl, '_self'));
};
