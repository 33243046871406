import type { Status } from './eventTypes';

export async function authFetch<R = any>(
    authToken: string,
    url: `/web/${string}`,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
    body?: any
): Promise<Status<R>> {
    const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_ENDPOINT_URL}${url}`,
        {
            method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            body: body ? JSON.stringify(body) : undefined,
        }
    ).then((response) => response.json());
    // todo: send not the only message to the user, and handle it everywhere where this function is used
    if (response?.status === false) throw response?.msg;
    return response;
}
