import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useAuth } from '@/utils/auth';

export const RouteGuard = ({ children }) => {
    const router = useRouter();
    const { isSignedIn } = useAuth();
    const [authorized, setAuthorized] = useState(false);

    useEffect(() => {
        checkAuth(router.asPath);

        router.events.on('routeChangeStart', hideContent);
        router.events.on('routeChangeComplete', checkAuth);

        return () => {
            router.events.off('routeChangeStart', hideContent);
            router.events.off('routeChangeComplete', checkAuth);
        };
    }, [isSignedIn()]);

    useEffect(() => {
        document.body.style.setProperty(
            'opacity',
            authorized || isUnauthorizedPage(router.asPath) ? '100%' : '10%'
        );
    }, [authorized, router.asPath]);

    const hideContent = () => setAuthorized(false);
    const showContent = () => setAuthorized(true);

    const isUnauthorizedPage = (url) =>
        url.includes('/create') ||
        url.includes('/login') ||
        url.includes('/registration/company-creation');

    const checkAuth = (url) => {
        let returnUrl = '';

        if (!isUnauthorizedPage(url)) {
            returnUrl = url;
        }

        if (!isSignedIn() && !isUnauthorizedPage(url)) {
            hideContent();
            router.push(
                {
                    pathname: '/login',
                    query: { returnUrl },
                },
                undefined,
                { shallow: true }
            );
        } else {
            showContent();
        }
    };

    return children;
};
